import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  CustomInput,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Input,
  Form,
  Col,
  Badge,
} from "reactstrap";

import { DayPicker } from "react-day-picker";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";
import moment from "moment";

import {
  faCalendar,
  faPlus,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InformationModal from "../InformationModal";
import Loader from "../Loader";
import { workTimesApi } from "../../services/workTimesServices";
import { utils } from "../../utils/utils";
import TooltipItem from "../TooltipItem";

const AddWorkTimeModal = ({ onSubmit, onClose }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [trip, setTrip] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [dateSelectorOpen, setDateSelectorOpen] = useState();
  const [loading, setLoading] = useState();

  const [employeeEnabled, setEmployeeEnabled] = useState({});

  const [timeToAdd, setTimeToAdd] = useState({});
  const [overTimeToAdd, setOverTimeToAdd] = useState({});
  const [startTimeToAdd, setStartTimeToAdd] = useState({});
  const [endTimeToAdd, setEndTimeToAdd] = useState({});

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const doSubmit = async (event) => {
    event.preventDefault();
    const workOrderEmployeeIds = new Set([
      ...Object.keys(timeToAdd).filter((key) => timeToAdd[key]),
      ...Object.keys(overTimeToAdd).filter((key) => overTimeToAdd[key]),
      ...Object.keys(startTimeToAdd).filter((key) => startTimeToAdd[key]),
      ...Object.keys(startTimeToAdd).filter((key) => startTimeToAdd[key]),
    ]);

    if (!selectedDate) {
      return setInformationModal({
        isOpen: true,
        title: `Add Work Time`,
        body: "Select the date.",
      });
    }
    const date = moment(selectedDate).format("YYYY-MM-DD");
    const employees = Array.from(workOrderEmployeeIds).map(
      (workOrderEmployeeId) => ({
        date,
        workOrderEmployeeId,
        hours: !workOrderDetails.workOrder.isNewTimeEntry
          ? timeToAdd[workOrderEmployeeId] || 0
          : null,
        overtimeHours: !workOrderDetails.workOrder.isNewTimeEntry
          ? overTimeToAdd[workOrderEmployeeId] || 0
          : null,
        note: "Created from the web",
        startTime: workOrderDetails.workOrder.isNewTimeEntry
          ? startTimeToAdd[workOrderEmployeeId]
          : null,
        endTime: workOrderDetails.workOrder.isNewTimeEntry
          ? endTimeToAdd[workOrderEmployeeId]
          : null,
      })
    );

    if (!employees.length) {
      return setInformationModal({
        isOpen: true,
        title: `Add Work Time`,
        body: "Add time to at least one employee.",
      });
    }

    const employeeWorkDays = workOrderDetails.workOrder.workOrderTrips
      .flatMap((trip) => trip.workOrderEmployees)
      .flatMap((woe) =>
        woe.employeeWorkDays.map((ewd) => ({
          ...ewd,
          employee: woe.employee,
          workOrderEmployeeId: woe.id,
        }))
      )
      .filter((ewd) => ewd.date === date);

    const existingWorkTime = employeeWorkDays.find((ewd) =>
      employees.find((e) => e.workOrderEmployeeId === ewd.workOrderEmployeeId)
    );

    if (existingWorkTime?.workTimes?.length) {
      return setInformationModal({
        isOpen: true,
        title: `Add Work Time`,
        body: `Time has already been created for ${existingWorkTime.employee.firstName} ${existingWorkTime.employee.lastName}`,
      });
    }
    setLoading(true);
    try {
      await workTimesApi.createWorkTimes({
        employees,
      });
      setLoading(false);
      onSubmit();
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Add Work Time`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  const isValidDate = (date) => {
    const tripDates = getTripDates();
    if (!tripDates) {
      return;
    }
    const { startDate, endDate } = tripDates;
    const isBetweenTrip = moment(date).isBetween(
      startDate,
      endDate,
      "day",
      "[]"
    );
    if (!isBetweenTrip) {
      setInformationModal({
        isOpen: true,
        title: `Add Work Time`,
        body: `Date must be inside the trip dates`,
      });
      return true;
    }
  };

  const onSelectedDate = (date) => {
    const error = isValidDate(date);
    if (!error) {
      setSelectedDate(date);
      setDateSelectorOpen(false);
    }
  };

  useEffect(() => {
    setTimeToAdd({});
    return () => setTimeToAdd({});
  }, [selectedDate]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const getTripDates = () => {
    if (!trip) {
      return;
    }
    const workOrderTrip = workOrderDetails.workOrder.workOrderTrips.find(
      (t) => t.id === trip.id
    );
    //si es primer trip => pueden haber dias con fecha 2 dias <= wo.startDate
    const isFirstTrip = moment(workOrderTrip.startDate).isSameOrBefore(
      moment(workOrderDetails.workOrder.startDate)
    );
    //si es ultimo trip => pueden haber dias con fecha 2 dias >= wo.endDate
    const isLastTrip = moment(workOrderTrip.endDate).isSameOrAfter(
      moment(workOrderDetails.workOrder.endDate)
    );
    let startDate = moment(workOrderTrip.startDate);
    if (isFirstTrip) {
      startDate = startDate.subtract(2, "days");
    }
    let endDate = moment(workOrderTrip.endDate);
    if (isLastTrip) {
      endDate = endDate.add(2, "days");
    }
    return { startDate, endDate };
  };

  const tripDates = getTripDates();
  const enabledDays = []; // Habilitar solo estos días
  if (trip) {
    let currentDate = moment(tripDates.startDate);
    while (currentDate.isSameOrBefore(tripDates.endDate)) {
      const date = currentDate.format("YYYY-MM-DD");
      enabledDays.push(date);
      currentDate = currentDate.add(1, "day");
    }
  }

  const handleTimeChanged = (event, field, startTime, isBlur = false) => {
    let newValue = event.target.value;
    if (!newValue) {
      newValue = null;
    } else {
      let [hours, minutes] = newValue.split(":").map((part) => parseInt(part));
      const formattedMinutes = minutes.toString();
      const adjustedMinutes = Math.round(minutes / 15) * 15;
      if (isBlur && formattedMinutes.length === 1) {
        minutes = 0;
      }
      let currentDate = moment
        .utc(moment(selectedDate).format("YYYY-MM-DD"))
        .hours(hours)
        .minutes(formattedMinutes.length === 2 ? adjustedMinutes : minutes)
        .seconds(0)
        .milliseconds(0);
      if (
        field === "endTime" &&
        startTime &&
        moment(currentDate).isBefore(moment(startTime))
      ) {
        currentDate = currentDate.add(1, "days");
      }
      newValue = currentDate.toISOString();
    }
    return newValue;
  };

  const isNextDay = (endTime, startTime) => {
    return moment
      .utc(endTime, "YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      .isAfter(moment.utc(startTime, "YYYY-MM-DDTHH:mm:ss.SSS[Z]"), "day");
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Add Work Time</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup row className="d-flex align-items-center">
                <Label className="col-4 d-flex flex-column text-left mb-0">
                  <span>Trip Number</span>
                  <small className="text-warning">
                    {trip
                      ? `${tripDates.startDate.format(
                          "YYYY/MM/DD"
                        )} to ${tripDates.endDate.format("YYYY/MM/DD")}`
                      : null}
                  </small>
                </Label>
                <Col sm={8}>
                  <CustomInput
                    required={true}
                    id="tripSelect"
                    type="select"
                    name="tripSelect"
                    value={trip?.id || ""}
                    onChange={(event) => {
                      const trip =
                        workOrderDetails.workOrder.workOrderTrips.find(
                          (trip) =>
                            trip.id === parseInt(event.currentTarget.value)
                        );
                      setTrip(trip);
                    }}
                  >
                    <option value={""}>Select a Trip</option>
                    {workOrderDetails.workOrder.workOrderTrips.map((trip) => {
                      return (
                        <option key={trip.id} value={trip.id}>
                          {trip.tripNumber}
                        </option>
                      );
                    })}
                  </CustomInput>
                </Col>
              </FormGroup>
              {trip ? (
                <FormGroup row className="d-flex align-items-center">
                  <Label className="col-4 d-flex flex-column text-left mb-0">
                    <span>Date</span>
                    <small className="text-warning">
                      Click the date to{" "}
                      {dateSelectorOpen ? "select/close" : "change"}
                    </small>
                  </Label>
                  <div className="col-8 d-flex align-items-center justify-content-between">
                    <div className="flex-grow-1">
                      {dateSelectorOpen ? (
                        <DayPicker
                          className="date-picker bg-white rounded border d-flex justify-content-center"
                          mode="single"
                          defaultMonth={selectedDate}
                          selected={[selectedDate]}
                          modifiers={{
                            enabled: (day) =>
                              enabledDays.some((enabledDay) => {
                                const date = moment(day).format("YYYY-MM-DD");
                                return enabledDay === date;
                              }),
                          }}
                          modifiersClassNames={{
                            selected: "my-selected",
                            today: "my-today",
                            range_start: "my-range_start",
                            range_end: "my-range_end",
                          }}
                          onDayClick={onSelectedDate}
                        />
                      ) : (
                        <div
                          className="float-left d-flex align-items-center px-2 py-1 border rounded bg-white cursor-pointer"
                          onClick={() => setDateSelectorOpen(true)}
                        >
                          <FontAwesomeIcon
                            icon={faCalendar}
                            className="mr-2 text-secondary"
                          />
                          <span>
                            {selectedDate
                              ? moment(selectedDate).format("MM/DD/YYYY")
                              : "No Date Selected"}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </FormGroup>
              ) : null}
            </>
          )}
          {selectedDate && trip ? (
            <ListGroup className="mt-3">
              <ListGroupItem
                className="d-flex justify-content-between align-items-center py-2 bg-lighter"
                tag="div"
              >
                <span>Technician</span>
                <span></span>
              </ListGroupItem>
              {trip.workOrderEmployees.map((workOrderEmployee) => (
                <ListGroupItem
                  key={workOrderEmployee.id}
                  className="d-flex justify-content-between align-items-center py-2"
                  tag="div"
                >
                  <span>{`${workOrderEmployee.employee.firstName} ${workOrderEmployee.employee.lastName}`}</span>
                  <div>
                    {employeeEnabled[workOrderEmployee.id] ? (
                      <div className="min-width-250 d-flex align-items-center">
                        <ListGroup className="col-12 px-0">
                          <FontAwesomeIcon
                            onClick={() => {
                              setTimeToAdd({
                                ...timeToAdd,
                                [workOrderEmployee.id]: null,
                              });
                              setOverTimeToAdd({
                                ...overTimeToAdd,
                                [workOrderEmployee.id]: null,
                              });
                              setEmployeeEnabled({
                                ...employeeEnabled,
                                [workOrderEmployee.id]: false,
                              });
                              setStartTimeToAdd({
                                ...startTimeToAdd,
                                [workOrderEmployee.id]: null,
                              });
                              setEndTimeToAdd({
                                ...endTimeToAdd,
                                [workOrderEmployee.id]: null,
                              });
                            }}
                            icon={faTimesCircle}
                            className="mt-2 cursor-pointer text-danger bg-white upper-close"
                          />
                          {workOrderDetails.workOrder.isNewTimeEntry ? (
                            <ListGroupItem
                              className="d-flex justify-content-center align-items-center py-2"
                              tag="div"
                            >
                              <span
                                style={{
                                  marginRight: "10px",
                                  minWidth: "75px",
                                  textAlign: "left",
                                }}
                              >
                                Start Time
                              </span>
                              <Input
                                required
                                step={900}
                                placeholder="Start Time"
                                type="time"
                                value={
                                  utils.formatTimeWithSeconds(
                                    startTimeToAdd[workOrderEmployee.id]
                                  ) || ""
                                }
                                onChange={(event) => {
                                  const time = handleTimeChanged(
                                    event,
                                    "startTime",
                                    undefined,
                                    false
                                  );
                                  setStartTimeToAdd({
                                    ...startTimeToAdd,
                                    [workOrderEmployee.id]: time,
                                  });
                                }}
                                onBlur={(event) => {
                                  const time = handleTimeChanged(
                                    event,
                                    "startTime",
                                    undefined,
                                    true
                                  );
                                  setStartTimeToAdd({
                                    ...startTimeToAdd,
                                    [workOrderEmployee.id]: time,
                                  });
                                }}
                              />
                            </ListGroupItem>
                          ) : (
                            <ListGroupItem
                              className="d-flex justify-content-center align-items-center py-2"
                              tag="div"
                            >
                              <Input
                                required={!overTimeToAdd[workOrderEmployee.id]}
                                min={0}
                                max={24}
                                step={0.1}
                                placeholder={"Regular Hours"}
                                type="number"
                                value={timeToAdd[workOrderEmployee.id] || ""}
                                onChange={(event) =>
                                  setTimeToAdd({
                                    ...timeToAdd,
                                    [workOrderEmployee.id]:
                                      event.currentTarget.value,
                                  })
                                }
                              />
                            </ListGroupItem>
                          )}
                          {workOrderDetails.workOrder.isNewTimeEntry ? (
                            <ListGroupItem
                              className="d-flex justify-content-center align-items-center py-2"
                              tag="div"
                            >
                              <span
                                style={{
                                  marginRight: "20px",
                                  minWidth: "65px",
                                  textAlign: "left",
                                }}
                              >
                                End Time
                              </span>
                              <Input
                                required
                                step={900}
                                placeholder="End Time"
                                type="time"
                                value={
                                  utils.formatTimeWithSeconds(
                                    endTimeToAdd[workOrderEmployee.id]
                                  ) || ""
                                }
                                onChange={(event) => {
                                  const startTime =
                                    startTimeToAdd[workOrderEmployee.id];
                                  const time = handleTimeChanged(
                                    event,
                                    "endTime",
                                    startTime,
                                    false
                                  );
                                  setEndTimeToAdd({
                                    ...endTimeToAdd,
                                    [workOrderEmployee.id]: time,
                                  });
                                }}
                                onBlur={(event) => {
                                  const startTime =
                                    startTimeToAdd[workOrderEmployee.id];
                                  const time = handleTimeChanged(
                                    event,
                                    "endTime",
                                    startTime,
                                    true
                                  );
                                  setEndTimeToAdd({
                                    ...endTimeToAdd,
                                    [workOrderEmployee.id]: time,
                                  });
                                }}
                              />
                              {isNextDay(
                                endTimeToAdd[workOrderEmployee.id],
                                startTimeToAdd[workOrderEmployee.id]
                              ) && (
                                <TooltipItem
                                  title={"End time will be for the next day"}
                                >
                                  <Badge className="ml-2">+1</Badge>
                                </TooltipItem>
                              )}
                            </ListGroupItem>
                          ) : (
                            <ListGroupItem
                              className="d-flex justify-content-center align-items-center py-2"
                              tag="div"
                            >
                              <Input
                                required={!timeToAdd[workOrderEmployee.id]}
                                min={0}
                                max={24}
                                step={0.1}
                                placeholder={"Overtime Hours"}
                                type="number"
                                value={
                                  overTimeToAdd[workOrderEmployee.id] || ""
                                }
                                onChange={(event) =>
                                  setOverTimeToAdd({
                                    ...overTimeToAdd,
                                    [workOrderEmployee.id]:
                                      event.currentTarget.value,
                                  })
                                }
                              />
                            </ListGroupItem>
                          )}
                        </ListGroup>
                      </div>
                    ) : (
                      <Button
                        className="rounded"
                        size="sm"
                        color="warning"
                        onClick={() =>
                          setEmployeeEnabled({
                            ...employeeEnabled,
                            [workOrderEmployee.id]: true,
                          })
                        }
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="mr-2 text-white"
                        />
                        Add Time
                      </Button>
                    )}
                  </div>
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : null}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          {loading ? (
            <div className="min-width-250">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color={"success"} type={"submit"}>
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddWorkTimeModal;
