import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";

import Loader from "../../../../components/Loader";

import InformationModal from "../../../../components/InformationModal";
import SelectWrapper from "../../../../components/SelectWrapper";
import { commonApi } from "../../../../services/commonServices";
import { employeesApi } from "../../../../services/employeeServices";
import { CalendarGroupingApi } from "../../../../services/calendarGroupingServices";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { useAuth } from "../../../../providers/authProvider";

const PAGE_SIZE = 30;
const ROLE_TECHNICIAN = 3;

const CalendarGroupingModal = ({
  calendarGroupingParam = null,
  onSubmit,
  onClose,
}) => {
  const [calendarGrouping, setCalendarGrouping] = useState({
    name: "",
    order: "",
    color: "#000000",
    serviceLocation: "",
    employees: [],
  });

  const [authContext] = useAuth();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();
  const [loadingExistingGroupings, setLoadingExistingGroupings] =
    useState(false);
  const [existingGroupings, setExistingGroupings] = useState([]);

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  useEffect(() => {
    if (calendarGroupingParam) {
      setCalendarGrouping({
        name: calendarGroupingParam.name || "",
        order: calendarGroupingParam.order || "",
        color: calendarGroupingParam.color || "#000000",
        serviceLocation: calendarGroupingParam.serviceLocation || "",
        employees: calendarGroupingParam.employees || [],
      });
    }
  }, [calendarGroupingParam]);

  const convertData = (input) => {
    return {
      serviceLocationId: input.serviceLocation.id,
      name: input.name,
      order: parseInt(input.order),
      color: input.color || "#000000",
      employeesIds: input.employees.map((employee) => employee.id),
    };
  };

  const disableButton = () => {
    const { color, name, order, employees, serviceLocation } = calendarGrouping;
    return !(color && name && order && employees.length > 0 && serviceLocation);
  };

  const fetchCalendarGroupings = async (serviceLocationId) => {
    setLoadingExistingGroupings(true);
    try {
      const data = { serviceLocationId };
      const response = await CalendarGroupingApi.getCalendarGrouping(data);
      return response;
    } catch (err) {
      return setInformationModal({
        isOpen: true,
        title: "Calendar Grouping",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    } finally {
      setLoadingExistingGroupings(false);
    }
  };

  useEffect(() => {
    const fetchGroupings = async () => {
      const groupings = await fetchCalendarGroupings(
        calendarGrouping.serviceLocation.id
      );
      setExistingGroupings(groupings.data);
    };

    if (calendarGrouping.serviceLocation) {
      fetchGroupings();
    }
  }, [calendarGrouping.serviceLocation]);

  const findExistingEmployeesInGroups = (
    existingGroupings,
    newEmployeesIds
  ) => {
    if (!existingGroupings || existingGroupings.length === 0) return null;
    const employeesInGroups = existingGroupings
      .filter((group) => group.id !== calendarGroupingParam?.id)
      .flatMap((group) =>
        group.employees
          .filter((employee) => newEmployeesIds.includes(employee.id))
          .map((employee) => `${employee.firstName} ${employee.lastName}`)
      );
    return employeesInGroups.length > 0 ? employeesInGroups.join(", ") : null;
  };

  const findExistingGroupsToDelete = (existingGroupings, newEmployeesIds) => {
    if (!existingGroupings || existingGroupings.length === 0) return null;
    const groupsToDelete = existingGroupings
      .filter(
        (group) =>
          group.id !== calendarGroupingParam?.id &&
          group.employees.every((employee) =>
            newEmployeesIds.includes(employee.id)
          )
      )
      .map((group) => group.name);
    return groupsToDelete.length > 0 ? groupsToDelete.join(", ") : null;
  };

  const updateGroups = async (data, onSubmit) => {
    try {
      await CalendarGroupingApi.updateCalendarGrouping(data);

      const groupsToDelete = existingGroupings.filter(
        (group) =>
          group.id !== calendarGroupingParam?.id &&
          group.employees.every((employee) =>
            data.employeesIds.includes(employee.id)
          )
      );

      if (groupsToDelete.length) {
        for (const groupToDelete of groupsToDelete) {
          await CalendarGroupingApi.deleteCalendarGrouping(groupToDelete);
        }
      }

      setConfirmationModal(initConfirmationModal);
      onSubmit();
    } catch (err) {
      setConfirmationModal(initConfirmationModal);
      return setInformationModal({
        isOpen: true,
        title: "Calendar Grouping",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const createGroup = async (data, onSubmit) => {
    try {
      const response = await CalendarGroupingApi.createCalendarGrouping(data);
      if (response && response.error) {
        return setInformationModal({
          isOpen: true,
          title: "Calendar Grouping",
          body: response.error,
        });
      }
      onSubmit();
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Calendar Grouping",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const doSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = convertData(calendarGrouping);
      data.id = calendarGroupingParam?.id;

      if (calendarGroupingParam) {
        const existingEmployeesNames = findExistingEmployeesInGroups(
          existingGroupings,
          data.employeesIds
        );
        const groupsToDelete = findExistingGroupsToDelete(
          existingGroupings,
          data.employeesIds
        );
        if (existingEmployeesNames) {
          return setConfirmationModal({
            isOpen: true,
            onSubmit: async () => {
              try {
                await updateGroups(data, onSubmit);
              } catch (err) {
                setLoading(false);
                return setInformationModal({
                  isOpen: true,
                  title: "Calendar Grouping",
                  body: err.message,
                });
              } finally {
                setLoading(false);
              }
            },
            onClose: () => {
              setConfirmationModal(initConfirmationModal);
            },
            title: "Information Calendar Grouping",
            body: `<p class="text-center">The following employees are already assigned to groups:</p>
              <strong>${existingEmployeesNames}</strong>
              <p></p>
                <p>Do you want to update them to this group?</p>
              ${
                groupsToDelete
                  ? `<p>Please note that these groups will be deleted:</p>
               <strong>${groupsToDelete}</strong>`
                  : ""
              }`,
            confirmColor: "danger",
          });
        } else {
          await updateGroups(data, onSubmit);
        }
      } else {
        await createGroup(data, onSubmit);
      }
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Calendar Grouping",
        body: err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOrderChange = async (newOrder) => {
    setCalendarGrouping({ ...calendarGrouping, order: newOrder });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const renderOrderOptions = () => {
    const orderOptions = !calendarGroupingParam
      ? existingGroupings.length + 1
      : existingGroupings.length;
    const options = [];
    for (let i = 1; i <= orderOptions; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {!calendarGroupingParam ? "Add" : "Edit"} Calendar Grouping
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col sm="12">
                <FormGroup>
                  <Label for="name">
                    <span>Group Name</span>
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <Input
                    placeholder="Enter the group name"
                    name="name"
                    value={calendarGrouping.name.trimLeft()}
                    onChange={(e) =>
                      setCalendarGrouping({
                        ...calendarGrouping,
                        name: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="color">
                    <span>Select group color</span>
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <Input
                    placeholder="Select group color"
                    type="color"
                    name="color"
                    value={calendarGrouping.color}
                    onChange={(e) =>
                      setCalendarGrouping({
                        ...calendarGrouping,
                        color: e.target.value,
                      })
                    }
                    required
                    style={{ width: 100 }}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <span>Service Location</span>
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <div className="d-flex align-items-center">
                    <SelectWrapper
                      key={calendarGrouping.serviceLocation?.id}
                      isDisabled={calendarGroupingParam?.id ? true : false}
                      entity="service location"
                      formatItemFunction={(c) => {
                        return {
                          label: c.name,
                          value: c.id,
                        };
                      }}
                      isMulti={false}
                      fetchFunction={commonApi.getServiceLocations}
                      fetchParameters={{
                        pageSize: PAGE_SIZE,
                        serviceLocationId: authContext.serviceLocationId,
                      }}
                      defaultSelected={calendarGrouping.serviceLocation}
                      onSelected={(serviceLocation) => {
                        setCalendarGrouping({
                          ...calendarGrouping,
                          serviceLocation: serviceLocation,
                          employees: [],
                          order: "",
                        });
                      }}
                      isRequired={true}
                    />
                    {loadingExistingGroupings && (
                      <Spinner size="sm" color="primary" className="ml-2" />
                    )}
                  </div>
                </FormGroup>
                {calendarGrouping.serviceLocation ? (
                  <>
                    <FormGroup>
                      <Label for="order">
                        <span>Order</span>
                        <small className="text-danger ml-1">*</small>
                      </Label>
                      <Input
                        type="select"
                        name="order"
                        value={calendarGrouping.order}
                        onChange={(e) =>
                          handleOrderChange(parseInt(e.target.value))
                        }
                        required
                        disabled={loadingExistingGroupings}
                      >
                        <option value="">Select an order</option>
                        {renderOrderOptions()}
                      </Input>
                    </FormGroup>
                    <FormGroup className="mb-0">
                      <Label>
                        <span>Technicians</span>
                        <small className="text-danger ml-1">*</small>
                      </Label>
                      <SelectWrapper
                        key={`${calendarGrouping.serviceLocation?.id}`}
                        entity="employee"
                        formatItemFunction={(e) => {
                          const employeeName =
                            e.name || `${e.firstName} ${e.lastName}`;
                          const status = !e.isActive ? " (Inactive)" : "";
                          return {
                            label: `${employeeName}${status}`,
                            value: e.id,
                          };
                        }}
                        isMulti={true}
                        fetchFunction={employeesApi.getEmployees}
                        fetchParameters={{
                          pageSize: PAGE_SIZE,
                          roleId: ROLE_TECHNICIAN,
                          serviceLocationId:
                            calendarGrouping.serviceLocation?.id,
                          isActive: true,
                          sortBy: "firstName",
                        }}
                        defaultSelected={calendarGrouping.employees}
                        onSelected={(employees) => {
                          setCalendarGrouping({
                            ...calendarGrouping,
                            employees: employees,
                          });
                        }}
                        isRequired={true}
                      />
                    </FormGroup>
                  </>
                ) : null}
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button
                color={"primary"}
                type="submit"
                disabled={disableButton()}
              >
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CalendarGroupingModal;
