import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { Button, Collapse } from "reactstrap";

import {
  useWorkOrderDetails,
  ACTIONS,
} from "../../../../providers/workOrderDetailsProvider";

import ServicePerformed from "./ServicePerformed";

import Attachments from "./Attachments";
import Materials from "./Materials";
import CraneName from "../../cranes/CraneName";
import moment from "moment";

const CranesServiced = ({ trip, date, materials }) => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const toggleCrane = (craneId) => {
    if (workOrderDetails.activeCrane !== craneId) {
      setWorkOrderDetails({
        action: ACTIONS.CHANGE_CRANE,
        payload: {
          activeCrane: craneId,
        },
      });
    } else {
      setWorkOrderDetails({
        action: ACTIONS.CHANGE_CRANE,
        payload: {
          activeCrane: null,
        },
      });
    }
  };

  const employeeWorkDays = trip.workOrderEmployees
    .flatMap((workOrderEmployee) =>
      workOrderEmployee.employeeWorkDays.map((item) => ({
        ...item,
        workOrderEmployeeId: workOrderEmployee.id,
      }))
    )
    .filter((ewd) => moment(ewd.date).isSame(moment(date), "date"));

  return (
    <>
      <h5 className="mt-3 pl-1 pb-2 border-bottom">Cranes Serviced</h5>
      {workOrderDetails.workOrder.workOrderCranes?.length ? (
        <div className="col-12 px-0">
          {workOrderDetails.workOrder.workOrderCranes.map(({ id, crane }) => {
            const serviceNotes = employeeWorkDays
              .flatMap((ewd) =>
                ewd.workOrderServiceNotes.flatMap((sn) => ({
                  ...sn,
                  employeeWorkDayId: ewd.id,
                }))
              )
              .filter((serviceNote) => serviceNote.workOrderCraneId === id)
              .sort((x, y) =>
                moment(y.updatedAt).isBefore(moment(x.updatedAt)) ? 1 : -1
              );
            const attachments = employeeWorkDays
              .flatMap((ewd) => ewd.attachments)
              .filter((attachment) => attachment.workOrderCraneId === id);
            const workOrderMaterials = employeeWorkDays
              .flatMap((ewd) => ewd.workOrderMaterials)
              .filter(
                (workOrderMaterial) => workOrderMaterial.workOrderCraneId === id
              );
            //todo add here who did added each data: ServicePerformed, Attachments, Materials, its all mixed
            return (
              <div className="d-flex flex-column mb-2" key={id}>
                <Button
                  onClick={() => toggleCrane(id)}
                  style={{ width: "100%" }}
                >
                  <div className="d-flex flex-row justify-content-between m-1 align-items-center">
                    <CraneName crane={crane} />
                    <FontAwesomeIcon
                      icon={
                        workOrderDetails.activeCrane === id
                          ? faChevronDown
                          : faChevronRight
                      }
                      fixedWidth
                      className="my-1"
                    />
                  </div>
                </Button>
                <Collapse
                  isOpen={workOrderDetails.activeCrane === id}
                  className="col-12 px-0"
                >
                  <ServicePerformed serviceNotes={serviceNotes} />
                  <Attachments
                    attachments={attachments}
                    employeeWorkDayId={employeeWorkDays.filter(
                      (ewd) => ewd.isLeadDay
                    )}
                  />
                  <Materials
                    workOrderMaterials={workOrderMaterials}
                    employeeWorkDayId={employeeWorkDays.filter(
                      (ewd) => ewd.isLeadDay
                    )}
                    materials={materials}
                  />
                </Collapse>
              </div>
            );
          })}
        </div>
      ) : (
        <small className="text-muted">No cranes to show</small>
      )}
    </>
  );
};

export default CranesServiced;
